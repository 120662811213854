body {
  background-color: #fff;
  /* color: white; */
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  margin: 0;
  padding: 0;
}

@media screen and (max-width: 600px) {
  body {
    padding-left: 2em;
    padding-right: 2em;
  }

  .fs-brand-tag-line {
    font-size: 35px !important;
  }

  brand-name-h1 {
    font-size: 30px;
  }
}

.fs-brand-tag-line {
  font-size: 75px;
  margin: 0;
  min-height: 2em;
  /* 2 lines of text */
}



.brand-name-h1 {
  font-size: 50px;
  margin: 0;
  font-weight: 600;
}

.brand-name-tag-line-h2 {
  font-size: 10px;
  margin: 0;
}

.brand-name-tag-line-h2>strong {
  font-size: 15px;
  margin: 0;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}